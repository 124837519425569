<script lang="ts" setup>
import ButtonPrime, {
  type ButtonSlots,
  type ButtonEmits as _ButtonEmits,
  type ButtonProps as _ButtonProps,
} from 'primevue/button'

interface ButtonProps extends /* @vue-ignore */ Omit<_ButtonProps, 'label' | 'icon'> {
  label?: _ButtonProps['label']
  icon?: _ButtonProps['icon']
}

interface ButtonEmits extends /* @vue-ignore */ _ButtonEmits {}

const props = defineProps<ButtonProps>()
defineEmits<ButtonEmits>()

const slots = defineSlots<ButtonSlots>()
</script>

<template>
  <ButtonPrime v-bind="$props">
    <template v-for="(_, slot) in slots" #[slot]="slotData">
      <slot :name="slot" v-bind="slotData"></slot>
    </template>
  </ButtonPrime>
</template>
